import React from 'react'
import {
    InputProps,
    TextField,
    TextFieldProps,
    FormHelperText
} from '@mui/material'
import {
    formatDocument,
    formatPhoneNumber,
    formatPhoneNumberResidencial,
    formatRG,
    formatStateEnrollment,
    removeNonNumeric
} from 'core/utils/globalFunctions'

type TDefaultTextField<T> = {
    name: string
    label: string
    value: T
    props?: InputProps & TextFieldProps
    small?: boolean
    error?: boolean | undefined
    inputMode?: "numeric" | "decimal" | "tel" | "search" | "email" | undefined
    helperText?: React.ReactNode
    type?: React.HTMLInputTypeAttribute
    onBlur?:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined
    onChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined
    style?: React.CSSProperties | undefined
    onChangeManual?: (value: string) => void
}

function GenericTextField<T>({
    name,
    label,
    value,
    props,
    helperText,
    error,
    type,
    onBlur,
    onChange,
    style,
    onChangeManual,
    inputMode 
}: TDefaultTextField<T>) {
    function documentField(name: string) {
        switch (name) {
            case 'stateEnrollment':
                return {
                    maxLength: 12,
                    value: formatStateEnrollment(
                        removeNonNumeric(value as string) ?? ''
                    )
                }
            case 'cpforcnpj':
            case 'login':
                return {
                    maxLength: 18,
                    value: formatDocument(value as string)
                }
            case 'cep':
            case 'addressDTO.zipCode':
                return {
                    maxLength: 8,
                    value: removeNonNumeric(value as string)
                }
            case 'rg':
                return {
                    maxLength: 9,
                    value: removeNonNumeric(value as string)
                }
            case 'contactDTO.telephone':
            case 'telephone':
                return {
                    maxLength: 11,
                    value: formatPhoneNumberResidencial(value as string)
                }

            case 'contactDTO.cellPhone':
            case 'contactDTO.cellPhone2':
            case 'cellPhone':
            case 'cellPhone2':
                return {
                    maxLength: 12,
                    value: formatPhoneNumber(value as string)
                }
            case 'addressDTO.number':
                return {
                    maxLength: 6,
                    value: String(value).replace(/[^a-zA-Z0-9]/g, '')
                }
            case 'lucroEtiqueta':
            case 'lucroRevenda':
                return {
                    value: String(value).replace(/[^0-9.,]/g, '')
                }
            case 'amount':
                return {
                    value: String(value).replace(/\D/g, '')
                }
            default:
                return {}
        }
    }

    const inputProps = documentField(name)

    const handlePaste = (
        event: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const pastedText = event.clipboardData.getData('text')

        // Se inputProps não for null, aplica manipulação
        if (
            name === 'cpforcnpj' ||
            name === 'login' ||
            name === 'cep' ||
            name === 'addressDTO.zipCode' ||
            name === 'rg' ||
            name === 'telephone' ||
            name === 'contactDTO.telephone' ||
            name === 'contactDTO.cell_phone2' ||
            name === 'addressDTO.number' ||
            name === 'stateEnrollment' || 
            name === 'cellPhone' ||
            name === 'cellPhone2'
        ) {
            const manipulatedValue = removeNonNumeric(pastedText)
            if (onChangeManual) {
                onChangeManual(manipulatedValue!)
            }
            event.preventDefault()
        } else {
            if (onChangeManual) {
                onChangeManual(pastedText.trim().replace(/\s+/g, ' ')!)
            }
            event.preventDefault()
        }
    }

    return (
        <TextField
            helperText={
                error && (
                    <FormHelperText
                        sx={{ margin: -0.5, padding: 0 }}
                        error={error}
                    >
                        {String(helperText)}
                    </FormHelperText>
                )
            }
            onPaste={
                handlePaste as unknown as React.ClipboardEventHandler<HTMLDivElement>
            }
            onChange={onChange}
            onBlur={onBlur}
            type={type ? type : undefined}
            size="small"
            variant="outlined"
            autoComplete="off"
            margin="none"
            id={name}
            label={label}
            inputMode={inputMode ? inputMode : undefined}
            value={value}
            error={error}
            style={style}
            inputProps={inputProps}
            {...props}
        />
    )
}

export default GenericTextField
