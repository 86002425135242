// export const BACKEND_BASE_URL = "https://api.lbs.bitbeelabs.tech/";
export const BACKEND_BASE_URL = 'https://api.lbs-new.bitbeelabs.tech/'

// * User
export const AUTH = `${BACKEND_BASE_URL}v1/auth/login`

// * Boletos
export const BOLETO = `${BACKEND_BASE_URL + 'v1/boletos/'}`

// * forgot password

export const SEND_MESSEGE = `${BACKEND_BASE_URL + 'v1/message/send-email'}`

export const VERIFICATION_TOKEN = `${
    BACKEND_BASE_URL + 'v1/message/verify-code'
}`

// SUPPLIER (FORNECEDOR)

export const SUPPLIER_UPDATE = `${BACKEND_BASE_URL + 'v1/supplier/update/'}`
export const SUPPLIER_SAVE = `${BACKEND_BASE_URL + 'v1/supplier/save'}`
export const SUPPLIER_LIST = `${BACKEND_BASE_URL + 'v1/supplier'}`
export const SUPPLIER_DETAILS = `${BACKEND_BASE_URL + 'v1/supplier/'}`
export const SUPPLIER_DELETE = `${BACKEND_BASE_URL + 'v1/supplier/delete/'}`

// COLABORADOR

export const COLLABORATOR_UPDATE = `${
    BACKEND_BASE_URL + 'v1/collaborator/update/'
}`
export const COLLABORATOR_SAVE = `${BACKEND_BASE_URL + 'v1/collaborator/save'}`
export const COLLABORATOR_LIST = `${BACKEND_BASE_URL + 'v1/collaborator'}`
export const COLLABORATOR_DETAILS = `${BACKEND_BASE_URL + 'v1/collaborator/'}`
export const COLLABORATOR_DELETE = `${
    BACKEND_BASE_URL + 'v1/collaborator/delete/'
}`

// CLIENTE

export const CLIENT_UPDATE = `${BACKEND_BASE_URL + 'v1/client/update/'}`
export const CLIENT_SAVE = `${BACKEND_BASE_URL + 'v1/client/save'}`
export const CLIENT_LIST = `${BACKEND_BASE_URL + 'v1/client'}`
export const CLIENT_DETAILS = `${BACKEND_BASE_URL + 'v1/client/'}`
export const CLIENT_DELETE = `${BACKEND_BASE_URL + 'v1/client/delete/'}`
export const CLIENT_SALE_DETAILS = `${BACKEND_BASE_URL + 'v1/client/items'}`
export const CLIENT_SALE = `${BACKEND_BASE_URL + 'v1/sales/client'}`
export const CLIENT_ACTVATE_UPDATE = `${BACKEND_BASE_URL + 'v1/client/active/'}`

// CATEGORIAS

export const CATEGORY_LIST = `${BACKEND_BASE_URL + 'v1/category'}`
export const CATEGORY_UPDATE = `${BACKEND_BASE_URL + 'v1/category/update/'}`
export const CATEGORY_SAVE = `${BACKEND_BASE_URL + 'v1/category/save'}`
export const ACTIVE_INATIVE_CATEGORIES = `${
    BACKEND_BASE_URL + 'v1/category/ativar-inativar/'
}`

// MARCA

export const BRAND_LIST = `${BACKEND_BASE_URL + 'v1/brand'}`
export const BRAND_UPDATE = `${BACKEND_BASE_URL + 'v1/brand/update/'}`
export const BRAND_SAVE = `${BACKEND_BASE_URL + 'v1/brand/save'}`
export const ACTIVE_INATIVE_BRANDS = `${
    BACKEND_BASE_URL + 'v1/brand/ativar-inativar/'
}`

// PRODUTOS

export const PRODUCT_LIST = `${BACKEND_BASE_URL + 'v1/product'}`
export const PRODUCT_UPDATE = `${BACKEND_BASE_URL + 'v1/product/'}`
export const PRODUCT_SAVE = `${BACKEND_BASE_URL + 'v1/product/save'}`
export const PRODUCT_IMAGE = `${BACKEND_BASE_URL + 'v1/image/'}`
export const PRODUCT_DELETE = `${BACKEND_BASE_URL + 'v1/product/'}`

// collection

export const COLLECTION_LIST = `${BACKEND_BASE_URL + 'v1/collection'}`
export const COLLECTION_UPDATE = `${BACKEND_BASE_URL + 'v1/collection/update/'}`
export const COLLECTION_SAVE = `${BACKEND_BASE_URL + 'v1/collection/save'}`
export const ACTIVE_INATIVE_COLLECTIONS = `${
    BACKEND_BASE_URL + 'v1/collection/ativar-inativar/'
}`

// indication

export const INDICATION_LIST = `${BACKEND_BASE_URL + 'v1/indication'}`
export const INDICATION_UPDATE = `${BACKEND_BASE_URL + 'v1/indication/update/'}`
export const INDICATION_SAVE = `${BACKEND_BASE_URL + 'v1/indication/save'}`
export const INDICATION_TYPE_LIST = `${BACKEND_BASE_URL + 'v1/type-indication'}`

// sales

export const SALES_LIST = `${BACKEND_BASE_URL + 'v1/sales'}`
export const SALES_LIST_DETAILS = `${BACKEND_BASE_URL + 'v1/sales/'}`
export const SALES_UPDATE = `${BACKEND_BASE_URL + 'v1/sales/'}`
export const SALES_SAVE = `${BACKEND_BASE_URL + 'v1/sales/'}`
export const PAY_PROMISSORY_SALE = `${BACKEND_BASE_URL + 'v1/sales/low-sales/'}`
export const PAY_CONSIGNED_SALE = `${
    BACKEND_BASE_URL + 'v1/sales/pay-consigned-sales/'
}`
export const SALE_PAYMENT_METHOD = `${
    BACKEND_BASE_URL + 'v1/payment-method/sales-payment-method'
}`
export const CANCEL_CONSIGNED_SALE = `${
    BACKEND_BASE_URL + 'v1/sales/cancel-consigned/'
}`

// characteristic
export const CHARACTERISTIC_LIST = `${BACKEND_BASE_URL + 'v1/characteristcs'}`
export const DELETE_CHARACTERISTICS = `${
    BACKEND_BASE_URL + 'v1/product/product-characteristics/'
}`

// sale

export const LIST_PAYMENT_TYPES = `${BACKEND_BASE_URL + 'v1/payment-method'}`
export const MAKE_SALE = `${BACKEND_BASE_URL + 'v1/sales'}`

// image
export const IMAGE_UPDATE = `${BACKEND_BASE_URL + 'v1/image/'}`
export const IMAGE_SAVE = `${BACKEND_BASE_URL + 'v1/image/save/'}`
export const IMAGE_DELETE = `${BACKEND_BASE_URL + 'v1/image/'}`

// filial
export const FILIAL_LIST = `${BACKEND_BASE_URL + 'v1/tenant'}`
