import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
import DefaultModal from "../defaultModal/defaultModal";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TCharacteristicsDTO, TProductBody, TProductRegister } from "core/models/product";
import { TCategoryBody } from "core/models/category";
import { TBrandBody } from "core/models/brand";
import { TCollectionBody } from "core/models/collection";
import { useFormik } from "formik";
import PriceTextField from "app/components/InputPrice/PriceTextField";
import GenericTextField from "app/components/genericTextField/GenericTextField";
import AsyncAutoComplete from "app/components/assyncronusAutoComplete/AssyncAutoComplete";
import {
  BRAND_LIST,
  CATEGORY_LIST,
  CHARACTERISTIC_LIST,
  COLLECTION_LIST,
  SUPPLIER_LIST,
} from "core/utils/constants";
import { TSupplierBody } from "core/models/supplier";
import {
  AddInfoContainer,
  InfosContainer,
  InputsPrice,
  LeftContent,
  MainContainer,
  PriceContainer,
  ProfitInPercentage,
  RightContent,
  RowAlign,
} from "./styles";
import CharacteristicList from "app/components/characteristicList/characteristicList";
import DefaultDialog from "../../defaultDialog/defaultDialog";
import { Validations } from "core/utils/validations";
import { ProductService } from "core/api/product/productService";
import { Notification } from "app/components/toastNotification/toastNotification";
import { AxiosError } from "axios";
import ProductImages from "app/components/uploadImage/productImages";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { IPage } from "core/models/utils";
import EditCharacteristics from "../editCharacteristic/editCharacteristics";

const initialValues: TProductRegister = {
  name: "",
  sku: "",
  amount: 0,
  priceCost: 0,
  priceTag: 0,
  resalePrice: 0,
  characteristicsDTOList: [],
  lucroEtiqueta: 0,
  lucroRevenda: 0,

};

const initialCharacteristics: TCharacteristicsDTO = {
  name: "",
  amount: 0,
  description: "",
};

interface IProductModalProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<IPage<TProductBody | undefined>, Error>>
}


const RegisterProductModal = (props: IProductModalProps) => {
  const { isOpen, onClose, onOpen, refetch } = props;
  const [editCaracteristic, setEditCaracteristic] = useState<TCharacteristicsDTO | undefined>();
  const [editCaracteristicModal, setEditCaracteristicModal] = useState(false);

  const callCreateProduct = async (newProduct: TProductRegister) => {

    let cleanedProduct: any = {
      name: newProduct.name,
      sku: newProduct.sku,
      quantityProduct: formik.values.characteristicsDTOList.length > 0 ? totalCharacteristics : Number(newProduct.amount),
      priceCost: priceCost ?? 0,
      resalePrice: resalePrice ?? 0,
      priceTag: priceTag ?? 0,
      brandId: newProduct.brandId,
      categoryId: newProduct.categoryId,
      supplierId: newProduct.supplierId,
      collectionId: newProduct.collectionId,
      characteristicsDTOList: newProduct.characteristicsDTOList,
    };

    setIsLoading(true)
    const images = [];
    if (formik.values.productImages?.imageOne) {
      images.push({ imageBase64: formik.values.productImages.imageOne, typeImage: "PNG" });
    }
    if (formik.values.productImages?.imageTwo) {
      images.push({ imageBase64: formik.values.productImages.imageTwo, typeImage: "PNG" });
    }
    if (formik.values.productImages?.imageThree) {
      images.push({ imageBase64: formik.values.productImages.imageThree, typeImage: "PNG" });
    }

    ProductService.createProduct({
      ...cleanedProduct,
      dataSaveImage: images
    })
      .then((resp) => {
        handleResetStates();
        setIsLoading(false)
        onClose()
        refetch()
      })
      .catch((err: AxiosError) => {
        Notification(String(err.message), "error")
        setIsLoading(false);
      });

  };

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validationSchema: Validations.ProductRegisterSchema,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      callCreateProduct(values)
    },
  });


  const totalCharacteristics = useMemo(() => {
    const totalAmountCharacteristics = formik.values.characteristicsDTOList
      .map((item) => item.amount)
      .reduce((acc, curr) => acc + curr, 0)
    return totalAmountCharacteristics;
  },
    [formik.values.characteristicsDTOList]);

  const [isLoading, setIsLoading] = useState(false);

  const [priceCost, setPriceCost] = useState<number | undefined>();
  const [priceTag, setPriceTag] = useState<number | undefined>();
  const [resalePrice, setResalePrice] = useState<number | undefined>();

  // ? adicionar uma caracteristica
  const [charDialog, setCharDialog] = useState(false);
  const [tempCharacteristic, setTempCharacteristic] =
    useState<TCharacteristicsDTO>(initialCharacteristics);
  const [errorDialog, setErrorDialog] = useState("");



  const handleResetStates = () => {
    setPriceCost(undefined);
    setPriceTag(undefined);
    setResalePrice(undefined);
    setIsLoading(false);
    formik.resetForm();
  };

  const removeCharacteristic = (rmvItemId: string) => {
    formik.setFieldValue(
      "characteristicsDTOList",
      formik.values.characteristicsDTOList.filter((item) => item.id !== rmvItemId)
    );
  };

  const handleEditCharacteristic = (id: string) => {
    const characteristic = formik.values.characteristicsDTOList.find((item) => item.id === id);
    setEditCaracteristic(characteristic);
    setEditCaracteristicModal(true);
  }

  const handleSelectSupplier = (selected: TSupplierBody) => {
    formik.setFieldValue("supplierId", selected.id);
  };

  const handleSelectCategory = (selected: TCategoryBody) => {
    formik.setFieldValue("categoryId", selected.id);
  };

  const handleSelectBrand = (selected: TBrandBody) => {
    formik.setFieldValue("brandId", selected.id);
  };

  const handleSelectCollection = (selected: TCollectionBody) => {
    formik.setFieldValue("collectionId", selected.id);
  };

  const handleClearSupplier = () => {
    formik.setFieldValue("supplierId", undefined);
  };

  const handleClearCategory = () => {
    formik.setFieldValue("categoryId", undefined);
  };

  const handleClearBrand = () => {
    formik.setFieldValue("brandId", undefined);
  };

  const handleClearCollection = () => {
    formik.setFieldValue("collectionId", undefined);
  };

  const handleResetForm = () => {
    onClose();
    formik.resetForm();
    setPriceCost(undefined);
    setPriceTag(undefined);
    setResalePrice(undefined);
  };

  // ? funções do dialog
  const handleConfirmCharacteristic = () => {
    handleAddCharacteristics(tempCharacteristic);
  };

  const handleCancelCharacteristic = () => {
    setCharDialog(false);
    setErrorDialog("");
    setTempCharacteristic(initialCharacteristics);
  };

  // ? função para editar caracteristicas
  const handleEditCharacteristics = useCallback(
    (updatedCharacteristic: TCharacteristicsDTO) => {
      const newList = formik.values.characteristicsDTOList.map((char) =>
        char.id === updatedCharacteristic.id
          ? { ...char, ...updatedCharacteristic }
          : char
      );

      // Validação de quantidade de características
      const totalAmount = newList
        .map((item) => item.amount)
        .reduce((acc, curr) => acc + curr, 0);
      const productAmount = formik.values.amount;


      if (productAmount < totalAmount) {
        console.error(
          "Erro: A quantidade da característica não pode ser maior que a quantidade do produto"
        );
        setErrorDialog(
          "A quantidade da característica não pode ser maior que a quantidade do produto"
        );
        return;
      } else {
        // Caso não tenha dado erro
        setEditCaracteristicModal(false)
        formik.setFieldValue("characteristicsDTOList", newList);
        setErrorDialog("");
        setTempCharacteristic(initialCharacteristics);
      }
    },
    [formik]
  );

  const handleSelectCharacteristics = (selected: TCharacteristicsDTO) => {
    setTempCharacteristic({ ...selected, id: selected.id, name: selected.name })
  };

  const handleAddCharacteristics = useCallback(
    (newCharacteristic: TCharacteristicsDTO) => {
      let newChar: TCharacteristicsDTO = {
        id: uuidv4(),
        characteristcId: newCharacteristic.id,
        name: newCharacteristic.name,
        amount: newCharacteristic.amount,
        description: newCharacteristic.description,
      };

      let newList = [...formik.values.characteristicsDTOList, newChar];

      // Caso não tenha dado erro
      formik.setFieldValue("characteristicsDTOList", newList);
      setCharDialog(false);
      setErrorDialog("");
      setTempCharacteristic(initialCharacteristics);

    },
    [formik]
  );


  const calcularLucro = (costPrice: number, profitPercentage: number): number => {
    return costPrice * (1 + profitPercentage / 100);
  };


  const handleProfitChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: 'revenda' | 'etiqueta'
  ) => {
    const cleanedValue = event.target.value.replace(/[^0-9.,]/g, '');
    const profitPercentage = parseFloat(cleanedValue);

    // Substituir a vírgula por ponto para garantir o formato numérico correto
    const normalizedPriceCost = parseFloat(
      formik.values.priceCost.toString().replace('.', '').replace(',', '.')
    );

    if (!isNaN(profitPercentage) && !isNaN(normalizedPriceCost)) {
      if (type === 'revenda') {
        const updatedResalePrice = calcularLucro(normalizedPriceCost, profitPercentage);
        formik.setFieldValue('resalePrice', updatedResalePrice); // Atualiza o valor no Formik
        setResalePrice(updatedResalePrice * 10); // Atualiza o estado local
      } else if (type === 'etiqueta' && resalePrice) {
        const updatedPriceTag = calcularLucro(resalePrice, profitPercentage);
        formik.setFieldValue('priceTag', updatedPriceTag);
        setPriceTag(updatedPriceTag);
      }
    }

    // Chame o handleChange do Formik após a limpeza do valor
    formik.handleChange(event);
  };

  const handleFocus = (event: any) => {
    event.target.select();
  };


  return (
    <>
      <DefaultDialog
        isOpen={charDialog}
        title={"Nova característica"}
        onCloseAction={() => handleCancelCharacteristic()}
        confirmAction={() => handleConfirmCharacteristic()}
        disabled={
          tempCharacteristic.name === "" ||
          tempCharacteristic.amount === 0 ||
          tempCharacteristic.description === "" ||
          tempCharacteristic.characteristcId === 0
        }
        body={
          <AddCharacteristicContent
            qtd={
              formik.values.amount -
              formik.values.characteristicsDTOList
                .map((item) => item.amount)
                .reduce((acc, curr) => acc + curr, 0)
            }
            error={errorDialog}
            tempCharacteristic={tempCharacteristic}
            setTempCharacteristic={setTempCharacteristic}
          />
        }
      />


      <DefaultModal
        title="Cadastrar Produto"
        isOpen={isOpen}
        onClose={handleResetForm}
        onOpen={onOpen}
        children={
          <MainContainer>
            <LeftContent>
              <ProductImages formik={formik} />
              <PriceContainer>
                <InputsPrice>
                  <PriceTextField
                    name="priceCost"
                    label="Preço de Custo"
                    value={formik.values.priceCost.toString()}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      formik.handleChange(event);
                    }}
                    error={!!formik.errors.priceCost}
                    helperText={formik.errors.priceCost}
                    priceNumber={priceCost}
                    setPriceNumber={setPriceCost}
                    style={{ display: "flex", flex: 2 }}
                  />

                  <ProfitInPercentage>
                    <Typography sx={{ fontSize: "1.1pc" }}>Lucro em %</Typography>
                    <GenericTextField<number>
                      name="lucroRevenda"
                      label="Revenda"
                      inputMode="decimal"
                      value={formik.values.lucroRevenda || 0}
                      onChange={(event) => handleProfitChange(event, 'revenda')}
                      style={{ display: "flex", width: "40%" }}
                      props={{ variant: "standard", disabled: !formik.values.priceCost, onFocus: handleFocus, onClick: handleFocus }}
                    />
                  </ProfitInPercentage>
                  <PriceTextField
                    name="resalePrice"
                    label="Preço de Revenda"
                    value={formik.values.resalePrice.toString()}
                    error={!!formik.errors.resalePrice}
                    onChange={(event => formik.handleChange(event))}
                    priceCost={priceCost}
                    formik={formik}
                    typeLucro={'lucroRevenda'}
                    helperText={formik.errors.resalePrice}
                    priceNumber={resalePrice}
                    setPriceNumber={setResalePrice}
                    style={{ display: "flex", flex: 2 }}
                  />

                  <ProfitInPercentage>
                    <Typography sx={{ fontSize: "1.1pc" }}>Lucro em %</Typography>
                    <GenericTextField<number>
                      name="lucroEtiqueta"
                      label="Etiqueta"
                      inputMode="decimal"
                      value={formik.values.lucroEtiqueta || 0}
                      onChange={(event) => handleProfitChange(event, 'etiqueta')}
                      style={{ display: "flex", width: "40%" }}
                      props={{ variant: "standard", disabled: !formik.values.resalePrice, onFocus: handleFocus, onClick: handleFocus }}
                    />
                  </ProfitInPercentage>

                  <PriceTextField
                    name="priceTag"
                    label="Preço de Etiqueta"
                    value={formik.values.priceTag.toString()}
                    error={!!formik.errors.priceCost}
                    onChange={(event => formik.handleChange(event))}
                    resalePrice={resalePrice}
                    formik={formik}
                    typeLucro={'lucroEtiqueta'}
                    helperText={formik.errors.priceCost}
                    priceNumber={priceTag}
                    setPriceNumber={setPriceTag}
                    style={{ display: "flex", flex: 2 }}
                  />
                </InputsPrice>
                <Button
                  onClick={() => formik.handleSubmit()}
                  sx={{ display: "flex", flex: 1, marginTop: "0.3rem" }}
                >
                  {isLoading ? <CircularProgress size={25} /> : 'CADASTRAR'}
                </Button>
              </PriceContainer>
            </LeftContent>
            <RightContent>
              <InfosContainer>
                <GenericTextField<string>
                  error={!!formik.errors.name}
                  helperText={formik.errors.name}
                  small
                  name={"name"}
                  label={"Nome"}
                  value={formik.values.name}
                  props={{
                    onChange: formik.handleChange,
                  }}
                  onChangeManual={(e: string) =>
                    formik.setFieldValue('name', e)
                  }
                  style={{ width: "100%" }}
                />
                <GenericTextField<string>
                  error={!!formik.errors.sku}
                  helperText={formik.errors.sku}
                  small
                  name={"sku"}
                  label={"Código da Barras"}
                  value={formik.values.sku}
                  props={{
                    onChange: formik.handleChange,
                  }}
                  onChangeManual={(e: string) =>
                    formik.setFieldValue('sku', e)
                  }
                  style={{ width: "100%" }}
                />
                <RowAlign>
                  <GenericTextField<number>
                    error={!!formik.errors.amount}
                    helperText={formik.errors.amount}
                    small
                    name={"amount"}
                    label={"Quantidade"}
                    value={formik.values.characteristicsDTOList.length > 0 ? totalCharacteristics : formik.values.amount}
                    props={{
                      onChange: formik.handleChange,
                      disabled: formik.values.characteristicsDTOList.length > 0,
                    }}
                    style={{ display: "flex", flex: 1 }}
                  />
                  <AsyncAutoComplete
                    label="Procurar Fornecedor"
                    handleOnSelect={handleSelectSupplier}
                    urlToSearch={SUPPLIER_LIST}
                    sortField="nameRepresentative"
                    style={{ display: "flex", flex: 2 }}
                    error={formik.errors.supplierId}
                    haveError={!!formik.errors.supplierId}
                    searchParamName='nameRepresentative'
                    onClear={handleClearSupplier}
                  />
                </RowAlign>
                <AsyncAutoComplete
                  label="Procurar Categoria"
                  handleOnSelect={handleSelectCategory}
                  urlToSearch={CATEGORY_LIST}
                  sortField="name"
                  error={formik.errors.categoryId}
                  haveError={!!formik.errors.categoryId}
                  searchParamName='name'
                  onClear={handleClearCategory}
                  active="active"
                />
                <AsyncAutoComplete
                  label="Procurar Coleção"
                  handleOnSelect={handleSelectCollection}
                  urlToSearch={COLLECTION_LIST}
                  sortField="name"
                  error={formik.errors.collectionId}
                  haveError={!!formik.errors.collectionId}
                  searchParamName='name'
                  onClear={handleClearCollection}
                  active="active"
                />
                <AsyncAutoComplete
                  label="Procurar Marca"
                  handleOnSelect={handleSelectBrand}
                  urlToSearch={BRAND_LIST}
                  sortField="name"
                  error={formik.errors.brandId}
                  haveError={!!formik.errors.brandId}
                  searchParamName='name'
                  onClear={handleClearBrand}
                  active="active"
                />
              </InfosContainer>
              {/* tabela de caracteristicas */}
              <AddInfoContainer>
                <Button
                  disabled={isLoading}
                  variant="outlined"
                  onClick={() => setCharDialog(true)}
                  sx={{ display: "flex", width: "100%" }}
                >
                  + ADICIONAR INFORMAÇÃO
                </Button>
                <Box sx={{ overflowY: "scroll", height: 200 }}>
                  <CharacteristicList
                    rmv={removeCharacteristic}
                    list={formik.values.characteristicsDTOList}
                    edit={handleEditCharacteristic}
                  />
                </Box>

              </AddInfoContainer>
            </RightContent>
            {editCaracteristicModal && editCaracteristic &&
              <EditCharacteristics
                characteristic={editCaracteristic}
                handleEditCharacteristics={handleEditCharacteristics}
                tempCharacteristic={tempCharacteristic}
                formik={formik}
                isOpen={editCaracteristicModal}
                onClose={() => setEditCaracteristicModal(false)}
                onOpen={() => setEditCaracteristicModal(true)}
                handleSelectCharacteristic={handleSelectCharacteristics}
                error={errorDialog}
              />}
          </MainContainer>
        }
      />
    </>
  );
};

export default RegisterProductModal;

// ? conteúdo do dialog de adicionar caractesristica
interface IAddCharacteristicContentProps {
  error: string;
  qtd: number;
  tempCharacteristic: TCharacteristicsDTO;
  setTempCharacteristic: Dispatch<SetStateAction<TCharacteristicsDTO>>;
}

const AddCharacteristicContent = ({
  setTempCharacteristic,
  tempCharacteristic,
  error,
  qtd,
}: IAddCharacteristicContentProps) => {
  // ? função para alterar o estado da caracteristica por nome de atributo
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTempCharacteristic((prevState) => ({
      ...prevState,
      [name]: name === "amount" ? parseInt(value) : value,
    }));
  };

  // ? função para selecionar o tipo da caracteristica
  const handleSelectCategory = (selected: TCharacteristicsDTO) => {
    setTempCharacteristic({ ...selected, description: "", amount: tempCharacteristic.amount })
  };

  return (
    <Box>
      <Typography fontWeight={"bold"} textAlign={"center"}>
        Quantidade do produto disponível: {qtd}
      </Typography>
      <AsyncAutoComplete
        label="Atribuir caracteristica"
        handleOnSelect={handleSelectCategory}
        urlToSearch={CHARACTERISTIC_LIST}
        sortField="name"
        variant="standard"
        searchParamName='name'
        onClear={() => setTempCharacteristic(initialCharacteristics)}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
        }}
      >
        <TextField
          label="Descrição"
          variant="standard"
          name="description"
          value={tempCharacteristic.description}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Quantidade"
          variant="standard"
          name="amount"
          type="number"
          defaultValue={tempCharacteristic.amount}
          value={tempCharacteristic.amount ?? 0}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
      </Box>
      {error && (
        <FormHelperText
          sx={{ margin: -0.5, padding: 0, textAlign: "center" }}
          error={true}
        >
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};
